html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: sans-serif;
  font-weight: 100;
}

.capitalize {
  text-transform: capitalize;
}

.container {
  position: absolute;
  padding-left: 20%;
  padding-right: 20%;
}

table {
  border-collapse: collapse;
  overflow: hidden;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

th,
td {
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.2);
}

th {
  text-align: center;
}

tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

tbody td {
  position: relative;
}

tbody td:hover:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -9999px;
  bottom: -9999px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
}

section {
  padding-top: 66px;
}

nav {
  height: 100%;
  width: 100%;
  margin-bottom: 50px;
}

nav > .flexContainer > div {
  z-index: 5;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  font-size: 36px;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.flexContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
